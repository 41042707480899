import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Contact from '@components/Contact';
import RichText from '@core/RichText';
import { grid, hyphenate, layoutWrapper } from '@mixins';
import mq from '@mq';

const JobsPage = ({
  data: {
    contentfulJobsPage: { text },
    contentfulContact: contact,
  },
}) => (
  <Main>
    <Text text={text} />
    <Contact {...contact} />
  </Main>
);

const Main = styled.main`
  ${hyphenate};
`;

const Text = styled(RichText)`
  ${layoutWrapper};
  ${grid};

  padding-top: 5rem;
  padding-bottom: 10rem;

  p {
    ${mq.small} {
      font-size: 1.25rem;
      line-height: 1.7;
    }
  }

  > * {
    grid-column: 1 / -1;
  }
`;

export const JobsPageQuery = graphql`
  query JobsPageQuery {
    contentfulJobsPage {
      title
      text {
        raw
      }
    }
    contentfulContact {
      title
      address {
        raw
      }
      telephone
      fax
      email
      openingTimes {
        raw
      }
      image {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          quality: 75
        )
      }
    }
  }
`;

export default JobsPage;
